import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { boxShadow, MEDIA_MIN_MEDIUM } from "../../../constants"

const Container = styled.div`
  position: fixed;
  min-width: 30rem;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(p) => p.theme.colors.white};
  padding: 2rem 2rem 4rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: ${boxShadow};
  opacity: ${(p) => (p.show ? 2 : 0)};
  z-index: ${(p) => (p.show ? 10001 : -1)};
`

const CloseButton = styled.button`
  position: absolute;
  top: -2rem;
  right: -2rem;
  font-size: 2rem;
  cursor: pointer;
  background: ${(p) => p.theme.colors.secondary};
  width: 4rem;
  height: 4rem;
  border: none;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.3rem 0 0 0.1rem;

  ${MEDIA_MIN_MEDIUM} {
    transition: transform 0.2s;
    padding: 0.2rem 0 0 0.2rem;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  i {
    color: ${(p) => p.theme.colors.white};
    margin: 0;
  }
`

const Title = styled.h2`
  width: 100%;
  text-align: center;
`

const Links = styled.div`
  i {
    color: ${(p) => p.theme.colors.primary};
    margin: 1rem 2rem;
    font-size: 2.4rem;

    ${MEDIA_MIN_MEDIUM} {
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.3, 1.3);
      }
    }
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(p) => (p.show ? 1 : 0)};
  z-index: ${(p) => (p.show ? 10001 : -1)};
`

export default ({ open, setOpenShare }) => {
  const { t } = useTranslation("translations")
  const [url, setUrl] = useState(null)
  useEffect(() => {
    setUrl(window.location)
  }, [])

  return (
    <>
      <Overlay show={open} onClick={() => setOpenShare(false)} />
      <Container show={open}>
        <CloseButton aria-label="Close" onClick={() => setOpenShare(false)}>
          <i className="fal fa-times"></i>
        </CloseButton>
        <Title>{t("Share this product")}</Title>
        <Links>
          <a
            href={`https://www.facebook.com/sharer.php?u=${url}`}
            title="Share this product on Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href={`https://twitter.com/share?url=${url}`}
            title="Share this product on Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </Links>
      </Container>
    </>
  )
}
