import React, { useRef, useEffect } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../../constants"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(p) => p.theme.colors.white};
  z-index: 10000;
  height: 100vh;
  padding: 3rem;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.openZoom ? "1" : "0")};
  z-index: ${(p) => (p.openZoom ? "10000" : "-1")};
  display: flex;
  align-items: center;
`

const CloseButton = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1000;
`

const ZoomSlider = styled(Slider)`
  width: 90%;
  margin: 0 auto;
  max-height: 100vh;

  ${MEDIA_MIN_MEDIUM} {
    width: 95%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .slick-arrow {
    z-index: 1000;
    font-size: 2rem;
    color: ${(p) => p.theme.colors.black};
  }

  .slick-prev {
    left: -3rem;

    ${MEDIA_MIN_MEDIUM} {
      left: 4rem;
    }
  }

  .slick-next {
    right: -3rem;

    ${MEDIA_MIN_MEDIUM} {
      right: 4rem;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: -6rem;

    li {
      button {
        &:before {
          color: ${(p) => p.theme.colors.primary};
          font-size: 0.8rem;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: ${(p) => p.theme.colors.secondary};
          }
        }
      }
    }
  }
`

const ImageWrapper = styled.div`
  max-width: 55rem;
  margin: 0 auto;
  display: block !important;
`

export default ({
  openZoom,
  setOpenZoom,
  slideContent,
  imageIndex,
  name,
  ...other
}) => {
  const zoomSlider = useRef(null)

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    next,
    previous,
    ...props
  }) => <span {...props}>{children}</span>

  const NextArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-right"></i>
    </SlickButtonFix>
  )
  const PrevArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-left"></i>
    </SlickButtonFix>
  )

  useEffect(() => {
    zoomSlider.current.slickGoTo(imageIndex, true)
  }, [imageIndex])

  const mainImageSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: React.cloneElement(NextArrow, {
      next: "true"
    }),
    prevArrow: React.cloneElement(PrevArrow, {
      previous: "true"
    }),
    initialSlide: imageIndex
  }

  return (
    <Container openZoom={openZoom} {...other}>
      <CloseButton
        onClick={() => {
          setOpenZoom(false)
        }}
      >
        <i className="fal fa-times"></i>
      </CloseButton>
      <ZoomSlider {...mainImageSettings} ref={zoomSlider}>
        {slideContent.map((content, index) => (
          <ImageWrapper key={index}>{content}</ImageWrapper>
        ))}
      </ZoomSlider>
    </Container>
  )
}
