import React, { useContext } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { BrinkContext } from "../context/BrinkContext"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import Card from "../product/card/Card"
import LineHeading from "../ui/LineHeading"

const Contianer = styled.div`
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  margin: 3rem auto;
  padding: 1rem 1.5rem 2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) => (p.columns === "4" ? "100%" : `calc(100% / ${p.columns})`)};
    padding: 2rem ${(p) => (p.columns === "4" ? "0" : "5rem")};
    margin: 6rem auto;
  }

  .slick-slider {
    margin: 0 -0.75rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 0;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    padding: 0 0.75rem;

    ${MEDIA_MIN_MEDIUM} {
      padding: 0;
    }

    > div {
      height: 100%;
    }

    > div > div {
      height: 100%;
    }
  }

  .slick-arrow {
    z-index: 1;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;

    &:before {
      display: none;
    }

    i {
      font-size: 2.5rem;
      color: ${(p) => p.theme.colors.black};
    }
  }

  .slick-prev {
    left: -4rem;
  }

  .slick-next {
    right: -4rem;
  }

  .slick-dots {
    position: absolute;
    bottom: -1rem;

    ${MEDIA_MIN_MEDIUM} {
      bottom: -3rem;
    }

    li {
      button {
        &:before {
          color: ${(p) => p.theme.colors.primary};
          font-size: 0.8rem;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: ${(p) => p.theme.colors.secondary};
          }
        }
      }
    }
  }
`

export default ({ products, title, columns, ...other }) => {
  const { languageCode } = useContext(BrinkContext)
  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    next,
    previous,
    ...props
  }) => <span {...props}>{children}</span>

  const NextArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-right"></i>
    </SlickButtonFix>
  )
  const PrevArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-left"></i>
    </SlickButtonFix>
  )

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: parseInt(columns, 10),
    slidesToScroll: 1,
    nextArrow: React.cloneElement(NextArrow, {
      next: true
    }),
    prevArrow: React.cloneElement(PrevArrow, {
      previous: true
    }),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Contianer columns={columns} {...other}>
      {title && (
        <LineHeading>
          {typeof title === "object" ? title[languageCode] || title.en : title}
        </LineHeading>
      )}
      <Slider {...settings}>
        {products.map((product) => (
          <Card key={uuidv4()} product={product} columns="1" slider />
        ))}
      </Slider>
    </Contianer>
  )
}
