import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const LineHeading = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 4rem;
  position: relative;
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3rem;
  }

  span {
    display: inline-block;
    padding: 0 2rem;
    background: ${(p) => p.theme.colors.white};
    position: relative;
    z-index: 1;

    i {
      color: ${(p) => p.theme.colors.secondary};
    }
  }

  &:before {
    content: "";
    height: 0.1rem;
    background: ${(p) => p.theme.colors.primaryLight};
    position: absolute;
    left: 3rem;
    right: 3rem;
    top: 0.8rem;
    z-index: 0;

    ${MEDIA_MIN_MEDIUM} {
      left: 0;
      right: 0;
      top: 1.4rem;
    }
  }
`

export default ({ children, ...other }) => (
  <LineHeading {...other}>
    <span>{children}</span>
  </LineHeading>
)
