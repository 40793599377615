import React from "react"
import styled from "styled-components"
import { ErrorMessage } from "react-hook-form"

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 2.2rem;

  > div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
`

const Checkbox = styled.input.attrs((p) => ({
  type: "checkbox",
  name: p.name,
  value: p.value
}))`
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  padding: 0;
  border: 0.1rem solid ${(p) => p.theme.colors.secondary};
  position: relative;
  flex-shrink: 0;
  background: ${(p) => p.theme.colors.white};
  margin: 0;

  &:checked::after {
    content: "";
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    width: 0.5rem;
    height: 1.3rem;
    transform: rotate(45deg);
    border-style: solid;
    border-color: ${(p) => p.theme.colors.secondary};
    border-width: 0 0.2rem 0.2rem 0;
  }

  &:focus {
    outline: none;
  }
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  width: 100%;
  padding-top: 1rem;
  letter-spacing: 0;
`

export default ({ children, name, value, errors, ...other }) => (
  <Container {...other}>
    <div>
      <Checkbox value={value} name={name} />
      {children}
    </div>
    {errors && (
      <ErrorMessage errors={errors} name={name}>
        {({ message }) => <Validation>{message}</Validation>}
      </ErrorMessage>
    )}
  </Container>
)
