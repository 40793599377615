import React, { useContext } from "react"
import Img from "gatsby-image"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../../context/BrinkContext"
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_X_LARGE } from "../../../constants"
import Button from "../../ui/Button"
import Price from "../Price"

const CardWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
  width: ${(p) => (p.slider ? "100%" : "calc(50% - 0.2rem)")};

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) => (p.slider ? "100%" : "calc(100% / 3 - 0.2rem)")};
  }

  ${MEDIA_MIN_X_LARGE} {
    width: calc(100% / ${(p) => p.columns} - 0.2rem);
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const Image = styled(Img)`
  max-width: 100%;
  margin: 0 auto;
`

const SecondaryImage = styled(Img)`
  position: absolute !important;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s;
`

const Name = styled.h2`
  margin: 2rem 0 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "Josefin Sans", "sans-serif";
  text-align: center;
  letter-spacing: 0.1rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin: 2.5rem 0 1rem;
  }
`

const Description = styled.div`
  font-size: 1.3rem;
  color: ${(p) => p.theme.colors.darkGrey};
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`

const PriceContainer = styled.div`
  width: 100%;
`

const FormattedPrice = styled(Price)`
  margin: 1.2rem auto 2rem;
  font-size: 1.8rem;
  flex-wrap: wrap;
  justify-content: center;

  span {
    margin-bottom: 0.2rem;
  }
`

const ActionButton = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 3rem;
  display: inline-flex;
  flex-wrap: nowrap;
  width: auto;
  padding: 1.4rem 2rem 1rem;
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  margin: 0 auto;
  height: auto;
  transition: all 0.2s;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
  }
`

const DisabledButton = styled(ActionButton)`
  background: ${(p) => p.theme.colors.mediumGrey};
  color: ${(p) => p.theme.colors.white};
  cursor: initial;

  &:hover {
    background: ${(p) => p.theme.colors.mediumGrey};
  }
`

const Badge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem 0 0.6rem;
  background: ${(p) =>
    p.outOfStock ? p.theme.colors.mediumGrey : p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  text-transform: uppercase;
  z-index: 2;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1rem 2rem 0.8rem;
    left: auto;
    bottom: auto;
    font-size: 1.3rem;
    width: 8rem;
    height: 8rem;
    padding: 1.4rem 1rem 1rem;
    border-radius: 4rem;
  }
`

const GatsbyLink = ({ path, product, columns, slider, ...other }) => {
  const { t } = useTranslation("translations")
  const { languageCode, productVariantsOutOfStock } = useContext(BrinkContext)
  if (!product) return null

  const { displayName, slug, variants } = product
  if (!variants.length) return null

  const outOfStock = product.variants.every((v) => productVariantsOutOfStock.includes(v._id));

  const variant = product.variants[0]
  const {
    comingSoon,
    mainImage,
    badge,
    children,
    cartDescription
  } = variant
  const { price, discount } = children[0]

  if (!price) return null

  return (
    <CardWrapper slider={slider} columns={columns}>
      <Link
        {...other}
        to={`/product/${slug.current}/`}
        state={{ prevPath: path }}
      >
        <ImageContainer>
          <Image fluid={mainImage.asset.fluid} />
          {variant.images.length > 0 && (
            <SecondaryImage fluid={variant.images[0].asset.fluid} />
          )}
          {outOfStock && <Badge outOfStock>{t("Out of stock")}</Badge>}
          {comingSoon && !outOfStock && <Badge>{t("Coming soon")}</Badge>}
          {badge && badge.en && !outOfStock && !comingSoon && (
            <Badge>{badge[languageCode] || badge.en}</Badge>
          )}
        </ImageContainer>
        <div>
          <Name>{displayName[languageCode] || displayName.en}</Name>
          <Description>
            {cartDescription[languageCode] || cartDescription.en}
          </Description>
          <PriceContainer>
            <FormattedPrice price={price} allDiscount={discount} />
          </PriceContainer>
        </div>
        </Link>
        {outOfStock ? (
          <DisabledButton disabled aria-label="Out of stock">
            {t("Out of stock")}
          </DisabledButton>
        ) : comingSoon ? (
          <DisabledButton disabled aria-label="Coming soon">
            {t("Coming soon")}
          </DisabledButton>
        ) : (
              <ActionButton
              onClick = {() => navigate(`/product/${slug.current}`)} >
                {t("Buy now")}
              </ActionButton>
            )}
    </CardWrapper>
  )
}

const Card = styled(GatsbyLink)`
  text-align: center;
  margin-bottom: 0;
  padding: 2rem 0 3rem;
  text-decoration: none;
  color: ${(p) => p.theme.colors.black};
  display: block;
  height: 100%;

  &:focus {
    outline: none;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem 3rem 4rem;
    border: 0.1rem solid transparent;
    transition: border 0.3s;

    &:hover {
      border: 0.1rem solid ${(p) => p.theme.colors.primary};

      > div div:nth-child(2) {
        opacity: 1;
      }
    }
  }
`

export default Card
