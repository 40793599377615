import React from "react"
import styled from "styled-components"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"

const Contianer = styled.div`
  max-width: ${containerSmallMaxWidth};
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.primaryLight};

  ${MEDIA_MIN_MEDIUM} {
    margin: 7rem auto 0;
    padding: 3rem;
  }
`

const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default ({ title, url }) => (
  <Contianer>
    <Video>
      <iframe title={title} width="560" height="315" src={url}></iframe>
    </Video>
  </Contianer>
)
