import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`

const SizeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 0.1rem solid black;
  margin-right: 1rem;
  padding: 0.1rem 0 0 0.1rem;
  background-color: ${(p) => (p.active ? "#000000" : "#ffffff")};
  color: ${(p) => (p.active ? "white" : "null")};
`

const DisabledButton = styled(SizeButton)`
  background-color: ${(p) => p.theme.colors.lightGrey};
  color: #707b7c;
  opacity: 50%;
`

export default (props) => {
  if (props.variants.length < 2) return null
  const buttons = props.variants.map((v) => {
    if (!v.outOfStock) {
      return (
        <SizeButton
          key={v.id}
          active={v.id === props.currentVariant.id ? true : false}
          onClick={() => props.handler(v)}
        >
          {v.size}
        </SizeButton>
      )
    } else {
      return (
        <DisabledButton
          key={v.id}
          active={v.id === props.currentVariant.id ? true : false}
          onClick={() => props.handler(v)}
        >
          {v.size}
        </DisabledButton>
      )
    }
  })

  return <Container>{buttons}</Container>
}
