import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../../constants"
import Button from "../../ui/Button"
import { BrinkContext } from "../../context/BrinkContext"
import { useTranslation } from "react-i18next"
import SizeChooser from "./SizeChooser"
import Addons from "./Addons"

const ActionContainer = styled.div`
  display: flex;
`

const ChangeQty = styled.div`
  display: flex;
  margin-right: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.secondary};
`

const Qty = styled.div`
  display: flex;
  height: 4.8rem;
  align-items: center;
  justify-content: center;
  width: 2rem;
  padding-top: 0.2rem;
  transition: background 0.2s;

  ${MEDIA_MIN_MEDIUM} {
    width: 5rem;
  }
`
const QtyButton = styled(Qty)`
  display: flex;
  width: 5rem;
  cursor: pointer;
  padding-top: 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    &:hover {
      background: ${(p) => p.theme.colors.secondary};
      color: ${(p) => p.theme.colors.white};
    }
  }
`
const Increase = { ...QtyButton }
const Decrease = { ...QtyButton }

const AddToCartButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
`
const DisabledButton = styled(Button)`
  background: ${(p) => p.theme.colors.lightGrey};
  color: ${(p) => p.theme.colors.white};
  cursor: initial;

  &:hover {
    background: ${(p) => p.theme.colors.lightGrey};
  }
`

const initiateAddons = (compatibleAddons) => {
  if (compatibleAddons) {
    return compatibleAddons?.reduce((prev, curr) => {
      if (!curr.archived && curr.active) return { ...prev, [curr.name]: { ...curr, selected: false } }
      else return prev
    }, {})
  } else return {}
}

export default ({ variantInfo, compatibleAddons, pageContext }) => {
  const { addProductVariantsToCart, isLoading, setIsLoading } = useContext(BrinkContext)
  const { t } = useTranslation("translations")
  const { sanityConfigGeneralSettings } = pageContext
  const [qty, setQty] = useState(1)
  const [currentVariant, setCurrentVariant] = useState(null)
  const [addons, setAddons] = useState(initiateAddons(compatibleAddons))

  useEffect(() => {
    if (!variantInfo.length) return

    setCurrentVariant(variantInfo.find((v) => !v.outOfStock))
  }, [variantInfo])

  if (!currentVariant) return null

  const outOfStock = variantInfo ? variantInfo.outOfStock : false
  const comingSoon = variantInfo ? variantInfo.comingSoon : false

  const isAvailableForPurchase = () =>
    currentVariant && !outOfStock && !comingSoon && sanityConfigGeneralSettings.checkoutFlowEnabled

  return (
    <div>
      {!variantInfo[0].comingSoon ? (
        <SizeChooser variants={variantInfo} currentVariant={currentVariant} handler={setCurrentVariant} />
      ) : null}
      <ActionContainer>
        {isAvailableForPurchase() && (
          <ChangeQty>
            <Decrease
              role="button"
              aria-label="Decrease quantity"
              onClick={() => {
                if (qty !== 1) setQty(qty - 1)
              }}
            >
              <i className="fal fa-minus"></i>
            </Decrease>
            <Qty aria-live="assertive">{qty}</Qty>
            <Increase role="button" aria-label="Increase quantity" onClick={() => setQty(qty + 1)}>
              <i className="fal fa-plus"></i>
            </Increase>
          </ChangeQty>
        )}
        {!sanityConfigGeneralSettings.checkoutFlowEnabled ? (
          <DisabledButton disabled aria-label="Available soon">
            {t("Available soon")}
          </DisabledButton>
        ) : outOfStock ? (
          <DisabledButton disabled aria-label="Out of stock">
            {t("Out of stock")}
          </DisabledButton>
        ) : variantInfo[0].comingSoon ? (
          <DisabledButton disabled aria-label="Coming soon">
            {t("Coming soon")}
          </DisabledButton>
        ) : (
          <AddToCartButton
            aria-label="Add product to cart"
            isLoading={isLoading}
            onClick={() => {
              addProductVariantsToCart(
                currentVariant.id,
                Object.values(addons).filter((addon) => addon.selected),
                qty,
                true
              )
              setIsLoading()
            }}
          >
            {t("Buy now")}
          </AddToCartButton>
        )}
      </ActionContainer>
      {compatibleAddons && <Addons addons={addons} setAddons={setAddons}></Addons>}
    </div>
  )
}
