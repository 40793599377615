import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import { v4 as uuidv4 } from "uuid"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../components/context/BrinkContext"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE, MEDIA_MIN_X_LARGE } from "../constants"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Link from "../components/Link"
import ImageGallery from "../components/product/page/ImageGallery"
import Video from "../components/widgets/Video"
import ProductSlider from "../components/widgets/ProductSlider"
import Price from "../components/product/Price"
import Share from "../components/product/page/Share"
import Actions from "../components/product/page/Actions"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto 2rem;
  padding: 0 3rem;

  ${MEDIA_MIN_X_LARGE} {
    padding: 0;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }
`

const Information = styled.div`
  width: 100%;
  position: relative;
  padding: 3rem 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 50%;
    padding: 0.8rem 0 3rem 5rem;
  }
`

const ShareProduct = styled.div`
  position: absolute;
  top: 3.8rem;
  right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_MEDIUM} {
    top: 0.5rem;
  }
`

const ShareButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    font-size: 1.8rem;
    margin-left: 0.5rem;
  }
`

const Name = styled.h1`
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 0;
  padding-right: 9rem;
  text-align: left;

  ${MEDIA_MIN_LARGE} {
    font-size: 4rem;
    line-height: 4rem;
  }
`

const FormattedPrice = styled(Price)`
  margin-top: 3rem;

  span {
    margin: 0 0.5rem 0 0;
    font-size: 2.4rem;
  }
`

const Description = styled.p`
  line-height: 2.4rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  padding: 1rem 0 2rem;
`

const Ingredients = styled.div`
  line-height: 2.4rem;
  font-size: 1.4rem;
  max-height: ${(p) => (p.showIngredients ? "150rem" : "0")};
  transition: max-height 0.3s;
  overflow: hidden;

  p {
    line-height: 2.4rem;
    font-size: 1.4rem;
  }
`

const ToggleIngredients = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding-left: 0.7rem;
  }

  i {
    font-size: 2.2rem;
    padding-top: 0.2rem;
    transition: transform 0.3s;
    transform: rotate(${(p) => (p.showIngredients ? "180deg" : "0deg")});
  }
`

const RelatedProducts = styled(ProductSlider)`
  padding: 3rem 0;
`

export default ({ data: { sanityProduct, brinkcommerce }, pageContext, location }) => {
  const { t } = useTranslation("translations")
  const { languageCode, getStocks, cart } = useContext(BrinkContext)

  const { displayName, description, _rawIngredients, productVideoUrl, variants, slug } = sanityProduct
  const { relatedVariants, compatibleAddons } = brinkcommerce?.getProduct

  const { comingSoon, badge, relatedProducts, mainImage, images, _id } = variants[0]
  const brinkVariant = relatedVariants.filter((v) => v.id === _id)[0]
  if (!variants.length) return null
  if (!brinkVariant) return null

  const [showIngredients, setShowIngredients] = useState(false)
  const [openShare, setOpenShare] = useState(false)
  const [stocks, setStocks] = useState(null)
  const [variantInfo, setVariantInfo] = useState([])
  const [outOfStock, setOutOfStock] = useState(false)

  useEffect(() => {
    console.log("HERE")
    getStocks(relatedVariants.map((brinkVariant) => brinkVariant.id)).then((stocks = []) => {
      setStocks(
        stocks.reduce((acc, stock) => {
          acc[stock.id] = stock
          return acc
        }, {})
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  useEffect(() => {
    const variantInfo = variants.map((v) => {
      const stock = stocks?.[v._id] ?? {}
      const cartQuantity = (
        cart.cartItems.find((cartItem) => {
          return cartItem.id === v._id
        }) ?? { quantity: 0 }
      ).quantity
      const currentStock = Math.max((stock.availableQuantity ?? 0) - cartQuantity, 0)
      return {
        id: v._id,
        currentStock: currentStock,
        outOfStock: currentStock === 0,
        comingSoon: v.comingSoon,
        size: v.size
      }
    })

    setVariantInfo(variantInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stocks, cart.cartItems])

  useEffect(() => {
    if (stocks) {
      const outOfStock = variantInfo.every((v) => !!v.outOfStock)
      setOutOfStock(outOfStock)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantInfo])

  return (
    <Layout meta={displayName[languageCode] || displayName.en} invertedHeader pageContext={pageContext}>
      <Breadcrumbs location={location}>
        <Link to={`/product/${slug.current}/`}> {displayName[languageCode] || displayName.en}</Link>
      </Breadcrumbs>
      <Container>
        <Grid>
          <ImageGallery
            name={displayName.en}
            mainImage={mainImage}
            images={images}
            outOfStock={outOfStock}
            comingSoon={comingSoon}
            badge={badge}
            languageCode={languageCode}
          />
          <Information>
            <ShareProduct>
              <Share open={openShare} setOpenShare={setOpenShare} />
              <ShareButton
                onClick={() => setOpenShare(true)}
                onKeyDown={(event) => {
                  if (event.keyCode !== 13) return false
                  setOpenShare(true)
                }}
                tabIndex={0}
              >
                {t("Share")}
                <i className="fal fa-share-alt"></i>
              </ShareButton>
            </ShareProduct>
            <Name>{displayName[languageCode] || displayName.en}</Name>
            <FormattedPrice
              price={brinkVariant.price}
              allDiscount={brinkVariant.discount && brinkVariant.discount.length > 0 ? brinkVariant.discount : null}
            />
            <Description>{description[languageCode] || description.en}</Description>
            <Actions variantInfo={variantInfo} compatibleAddons={compatibleAddons} pageContext={pageContext}></Actions>
            {_rawIngredients && _rawIngredients.length > 0 && (
              <>
                <ToggleIngredients
                  showIngredients={showIngredients}
                  onClick={() => setShowIngredients(!showIngredients)}
                >
                  <i className="fal fa-angle-down"></i>
                  <span>{t("Show ingredients")}</span>
                </ToggleIngredients>
                <Ingredients showIngredients={showIngredients}>
                  {_rawIngredients.map((rawingredient) => (
                    <div key={uuidv4()}>
                      <h4>{rawingredient.en.title}</h4>
                      <BlockContent blocks={rawingredient.en.ingredientDescription} />
                    </div>
                  ))}
                </Ingredients>
              </>
            )}
          </Information>
        </Grid>
        {productVideoUrl && (
          <Video
            title={displayName.en}
            url={`${productVideoUrl}?rel=0&amp;amp;showinfo=0&amp;amp;color=white&amp;amp;modestbranding=1`}
          />
        )}
        {relatedProducts && relatedProducts.length > 0 && (
          <RelatedProducts products={relatedProducts} title={t("Related products")} columns="4" />
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($sanityProductId: String!, $brinkProductId: ID!) {
    sanityProduct(_id: { eq: $sanityProductId }) {
      id
      description {
        en
        zh
      }
      title
      displayName {
        en
        zh
      }
      productVideoUrl
      slug {
        current
      }
      variants {
        _id
        badge {
          en
          zh
        }
        relatedProducts {
          displayName {
            en
          }
          slug {
            current
          }
          variants {
            ...ProductVariants
          }
        }
        outOfStock
        comingSoon
        size
        mainImage {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
        images {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
        children {
          id
          ... on BrinkProductVariant {
            id
            name
            price {
              amount
              currencyUnit
            }
            discount {
              amount
              currencyUnit
            }
          }
        }
      }
      _rawIngredients(resolveReferences: { maxDepth: 10 })
    }
    brinkcommerce {
      getProduct(id: $brinkProductId) {
        relatedVariants {
          id
          name
          price {
            amount
            currencyUnit
          }
          discount {
            amount
            currencyUnit
          }
        }
        compatibleAddons {
          id
          name
          active
          archived
          price {
            amount
            currencyUnit
          }
          discount {
            amount
            currencyUnit
          }
        }
      }
    }
  }
`
