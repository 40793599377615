import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../../constants"

const Badge = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  left: auto;
  bottom: auto;
  width: 8rem;
  height: 8rem;
  padding: 1.2rem 1rem 1rem;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  background: ${(p) =>
    p.outOfStock
      ? p.theme.colors.mediumGrey
      : p.backgroundColor
      ? p.backgroundColor
      : p.theme.colors.secondary};
  color: ${(p) => p.textColor || p.theme.colors.white};
  text-transform: uppercase;
  z-index: 1;
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;
  }
`

export default ({
  outOfStock,
  text,
  backgroundColor,
  textColor,
  responsive = true,
  ...other
}) => (
  <Badge
    outOfStock={outOfStock}
    responsive={responsive}
    backgroundColor={backgroundColor}
    textColor={textColor}
    {...other}
  >
    {text}
  </Badge>
)
