import React, { useState } from "react"
import styled from "styled-components"
import Checkbox from "../../ui/Checkbox"
import Button from "../../ui/Button"
import Input from "../../ui/Input"
import Price from "../../../components/product/Price"

const GiftWrappingContainer = styled.div`
  display: flex;
  margin-top: 3rem;
`
const EngravingContainer = styled.div`
  display: flex;
  margin: 3rem 0 0;
  justify-content: flex-end;
`

const Wrapper = styled.div`
  margin-bottom: 3rem;
`

const Option = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  h3 {
    margin: 0;
    margin-top: 2rem;
  }

  p {
    margin: 1rem;
  }
`

const FormattedPrice = styled(Price)`
  span {
    margin: 0 0.5rem 0 0;
    font-size: 1.3rem;
  }
`

const Description = styled.label`
  line-height: 2.4rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  padding: 0rem 0 1.5rem 1rem;
`

const EngravingInput = styled(Input)`
  border-bottom: 1px solid black;
  width: 0;
  animation-name: ${(p) => p.showEngravingInput && "engraveInput"};
  animation-duration: 3s;
  animation-fill-mode: forwards;

  @keyframes engraveInput {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  input {
    text-transform: none;
  }
`
const ErrorMessage = styled.p`
  color: ${(p) => p.theme.colors.errors};
`

const EngravingButton = styled(Button)`
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  overflow: hidden;
  margin-left: ${(p) => (p.showEngravingInput ? "1rem" : "0")};
  transition: width 1s;
  position: relative;
  animation-name: ${(p) => p.showEngravingInput && "engraveBtn"};
  animation-duration: 3s;
  animation-fill-mode: forwards;

  @keyframes engraveBtn {
    from {
      width: 100%;
    }
    to {
      width: 15rem;
    }
  }
`

export default ({ addons, setAddons }) => {
  const [showEngravingInput, setShowEngravingInput] = useState(false)
  const [engravingText, setEngravingText] = useState()
  const [inputError, setInputError] = useState()

  const setSpecificAddon = (value, name) => {
    setAddons({ ...addons, [name]: { ...addons[name], selected: value } })
  }

  const setEngraveAddon = (key, value, name) => {
    setAddons({ ...addons, [name]: { ...addons[name], options: [{ key: key, value: value }], selected: true } })
  }

  const handleEngravingClick = () => {
    if (!showEngravingInput) return setShowEngravingInput(true)
    if (!engravingText || engravingText === "" || engravingText === " ")
      return setInputError({ error: "emptyInput", message: "Can't be empty" })
    setInputError()
    setEngraveAddon("ENGRAVE", engravingText, "Engraving")
  }

  return (
    <>
      {addons["Gift wrapping"] && (
        <GiftWrappingContainer>
          <Checkbox
            name="Gift wrapping"
            defaultValue={false}
            onChange={(e) => setSpecificAddon(e.target.checked, "Gift wrapping")}
          ></Checkbox>
          <Description>Check to add gift wrapping to the product</Description>
        </GiftWrappingContainer>
      )}
      {addons["Engraving"] &&
        (addons["Engraving"].selected ? (
          <Option>
            <h3>Engraving</h3>
            <div>
              <i className="fa-regular fa-pen-line" onClick={() => setShowEngravingInput(!showEngravingInput)}></i>
              <p>"{addons["Engraving"].options[0].value}"</p>
              <FormattedPrice price={addons["Engraving"].price} allDiscount={addons["Engraving"].discount} />
            </div>
          </Option>
        ) : (
          <Wrapper>
            <EngravingContainer>
              <EngravingInput
                onChange={(e) => setEngravingText(e.target.value)}
                showEngravingInput={showEngravingInput}
              ></EngravingInput>
              <EngravingButton onClick={handleEngravingClick} showEngravingInput={showEngravingInput}>
                {showEngravingInput ? "Engrave" : "Engraving"}
              </EngravingButton>
            </EngravingContainer>
            {inputError && <ErrorMessage>{inputError.message}</ErrorMessage>}
          </Wrapper>
        ))}
    </>
  )
}
