import React, { useRef, useState, useEffect } from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../../constants"
import ImageZoom from "./ImageZoom"
import Badge from "./Badge"

const Container = styled.div`
  width: 100%;
  padding: 0;
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
    width: 50%;
    max-width: 50%;
    display: flex;
    padding: 0 3rem 0 0;
  }
`

const Gallery = styled(Slider)`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0.8rem 0;
    order: 2;
    width: calc(100% - 11rem);
  }

  img {
    cursor: zoom-in;
  }

  i {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.black};
  }
`

const Thumbs = styled(Slider)`
  width: 100%;
  padding: 0;
  margin-top: 0.8rem;

  ${MEDIA_MIN_MEDIUM} {
    order: 1;
    flex-wrap: wrap;
    display: flex;
    max-width: 11rem;
  }

  img {
    max-width: 100%;
  }

  img,
  div {
    &:focus {
      outline: none;
    }
  }

  .slick-slide {
    padding: 1rem 1rem 0.8rem;
    border: 0.1rem solid transparent;

    ${MEDIA_MIN_MEDIUM} {
      min-width: 10rem;
      margin: 0 1rem 0 0;
    }
  }

  .slick-cloned {
    display: none !important;
  }

  .slick-current {
    border: 0.1rem solid ${(p) => p.theme.colors.primary};
  }
`

export default ({
  outOfStock,
  comingSoon,
  badge,
  mainImage,
  images,
  name,
  languageCode,
  ...other
}) => {
  const { t } = useTranslation("translations")
  let slideContent = []
  slideContent.push(<Img fluid={mainImage.asset.fluid} loading="lazy" />)
  images.forEach((image) => {
    slideContent.push(<Img fluid={image.asset.fluid} loading="lazy" />)
  })

  const [openZoom, setOpenZoom] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const slider1 = useRef(null)
  const slider2 = useRef(null)

  const gallerySettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav2
  }

  const thumbsSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: nav1
  }

  useEffect(() => {
    setNav1(slider1.current)
    setNav2(slider2.current)
  }, [])

  return (
    <Container {...other}>
      {outOfStock && <Badge outOfStock text={t("Out of stock")} />}
      {comingSoon && !outOfStock && <Badge text={t("Coming soon")} />}
      {badge && badge.en && !outOfStock && !comingSoon && (
        <Badge text={badge[languageCode] || badge.en} />
      )}
      <ImageZoom
        openZoom={openZoom}
        setOpenZoom={setOpenZoom}
        slideContent={slideContent}
        imageIndex={imageIndex}
        name={name}
      />
      <Gallery {...gallerySettings} ref={slider1}>
        {slideContent.map((content, index) => (
          <div
            key={index}
            role="button"
            aria-label="Open zoomed image"
            tabIndex={0}
            onClick={() => {
              setOpenZoom(true)
              setImageIndex(index)
            }}
            onKeyDown={(event) => {
              if (event.keyCode !== 13) return false
              setOpenZoom(true)
              setImageIndex(index)
            }}
          >
            {content}
          </div>
        ))}
      </Gallery>
      <Thumbs {...thumbsSettings} ref={slider2}>
        {slideContent.map((content, index) => (
          <div key={index}>{content}</div>
        ))}
      </Thumbs>
    </Container>
  )
}
